<template>
  <div class="container">
    <my-header :isSenior.sync="isSenior"/>
    <div class="table">
      <div class="title">报纸</div>
      <ul class="name">
        <li
          class="item"
          v-for="v in tableData"
          :key="v.id"
          @click="handleClick(v)"
        >
          {{ v.title }}
        </li>
      </ul>
    </div>
    <el-pagination
      :hide-on-single-page="true"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageIndex"
      :page-sizes="[50, 100]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
  
  <script>
import myHeader from "../../components/header/index.vue";
import { getPaper } from "../../api/literature.js";
export default {
  components: {
    myHeader,
  },
  data() {
    return {
      isSenior: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 50,
      total: 0,
      type: "", // 135 契约  132 地名辞书
      Title: "",
    };
  },
  created() {
    this.type = this.$route.query.type;
    if (this.type == 132) {
      this.pageSize = 50;
    }
    this.pageIndex = 1;
    this.getPaper();
  },
  methods: {
    async getPaper() {
      try {
        const res = await getPaper({
          PageIndex: this.pageIndex,
          PageSize: this.pageSize,
        });
        console.log(res, "res");
        this.tableData = res.items;
        this.total = res.totalCount;
      } catch (error) {
        console.log(error, "error");
      }
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getPaper();
    },
    handleCurrentChange(index) {
      this.pageIndex = index;
      this.getPaper();
    },
    onSearch() {
      this.pageIndex = 1;
      this.getPaper();
    },
    handleClick(data) {
      let routeData = this.$router.resolve({
        path: "/ebook",
        query: {
          sourceID: data.sourceID,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
  
  <style scoped lang="scss">
.container {
  .box {
    width: 1300px;
    padding: 30px 0 0 0;
    margin: 0 auto;

    .search {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      span {
        display: inline-block;
        width: 100px;
      }
      .el-input {
        width: 300px;
      }
    }
  }
  .table {
    width: 1300px;
    padding: 30px 0 0 0;
    margin: 0 auto;

    .title {
      padding: 10px;
      font-size: 18px;
      font-weight: 600;
      border-bottom: 1px solid #1a416c;
      background: #eee;
    }
    .name {
      display: flex;
      flex-wrap: wrap;
      background: #fff;

      .item {
        width: 250px;
        padding: 20px 5px;
        text-decoration: underline;
        color: #4c69bb;
        cursor: pointer;
        font-size: 16px;
      }

      .item:hover {
        color: red;
      }
    }
  }
  .el-pagination {
    text-align: center;
    padding-bottom: 10px;
  }
}
</style>